import { useState, useEffect, useCallback } from "react";
import { HTTPService } from "../services/http-service/http.service";
import { APIURLS } from '../constants/ApiUrls';

interface OnlineStatus {
  isOnline: boolean;
  wasOnline: boolean;
}

function useOnlineStatus(): boolean {
  const [status, setStatus] = useState<OnlineStatus>({
    isOnline: navigator.onLine,
    wasOnline: navigator.onLine,
  });

  const INTERNET_CHECK_URL = APIURLS.GET_TEST_URL; // Reliable endpoint

  const updateOnlineStatus = useCallback(async () => {
    try {
      const response = await HTTPService.getRequest(INTERNET_CHECK_URL);
      const isNowOnline = response.status === 200;

      // Update state only if the online status has changed
      if (isNowOnline !== status.isOnline) {
        setStatus((prevStatus) => ({
          isOnline: isNowOnline,
          wasOnline: prevStatus.isOnline,
        }));

        console.log(isNowOnline ? "You are online" : "You are offline");
      }
    } catch (error) {
      console.error("Error checking connectivity:", error);
      if (status.isOnline) {
        setStatus((prevStatus) => ({
          isOnline: false,
          wasOnline: prevStatus.isOnline,
        }));
        console.log("You are offline");
      }
    }
  }, [status.isOnline, INTERNET_CHECK_URL]);

  useEffect(() => {
    const handleOnline = () => {
      updateOnlineStatus();
    };

    const handleOffline = () => {
      setStatus((prevStatus) => ({
        isOnline: false,
        wasOnline: prevStatus.isOnline,
      }));
      console.log("You are offline");
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Perform initial check and set periodic checks
    updateOnlineStatus();
    const interval = setInterval(updateOnlineStatus, 5000);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      clearInterval(interval);
    };
  }, [updateOnlineStatus]);

  return status.isOnline;
}

export default useOnlineStatus;