import { BehaviorSubject } from "rxjs";
import { APIURLS } from "../../constants/ApiUrls";
import { HTTPService } from "../http-service/http.service";

export const AuthService = {
  sideNavCollapse: new BehaviorSubject<any>(false),
  userInfo: new BehaviorSubject<any>({}),
  networkIssue: new BehaviorSubject<any>(false),
  leftTableExpand: new BehaviorSubject<any>(false),
  middleTableExpand: new BehaviorSubject<any>(false),
  bottomTableExpand: new BehaviorSubject<any>(false),
  login: async (data: any) => {
    try {
      const response = await HTTPService.postRequest(APIURLS.LOGIN, data);
      return response;
    } catch (error) {
      return error;
    }
  },
  validateToken: async () => {
    try {
      const response = await HTTPService.postRequest(APIURLS.VERIFY_TOKEN);
      return response.data;
    } catch (error) {
      return error;
    }
  },
  rolesList: async () => {
    try {
      const response = await HTTPService.getRequest(APIURLS.ROLES_LIST);
      return response;
    } catch (error) {
      return error;
    }
  },
  ProductHierarchyList: async () => {
    try {
      const response = await HTTPService.getRequest(APIURLS.PRODUCT_HIERARCHY);
      return response;
    } catch (error) {
      return error;
    }
  },
};
