import axios from "axios";
import { TokenService } from "../token-service/token.service";
import { useHistory } from "react-router-dom";
import { ROUTE_CONSTANTS } from "../../constants/RouteConstants";
import { HTTP_RESPONSE } from "../../enums/http-response.enum";
import Cookies from "universal-cookie";
import { AuthService } from "../auth-service/auth.service";

const baseUrl = `${process.env.REACT_APP_API_BASEURL}`;
const cookies = new Cookies();

export let axiosInstance: any;
axiosInstance = axios.create({
  baseURL: baseUrl,
});

axiosInstance.interceptors.request.use(
  (config: any) => {
    const token = cookies.get("jwtToken");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    const domain = TokenService().getDomain();
    if (domain) {
      config.headers["x-subdomain"] = domain;
    }
    const origin = TokenService().getOrigin();
    if (origin) {
      config.headers["x-origin"] = origin;
    }
    return config;
  },
  (error: any) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response: any) => {
    AuthService.networkIssue.next(false);
    return response;
  },
  (error: any) => {
    if (error?.response?.status === HTTP_RESPONSE.UNAUTHORIZED) {
      cookies.remove("userInfo");
      cookies.remove("jwtToken");
      cookies.remove("userInfo");
      cookies.remove("rolesList");
      cookies.remove("currentRole");
      cookies.remove("currentRoleName");
      cookies.remove("planningElement");

      window.location.href = ROUTE_CONSTANTS.LOGIN;
    } else if (error?.message == "Network Error" && !error?.response?.status) {
      AuthService.networkIssue.next(true);
    }
    return Promise.reject(error);
  }
);
