export const APIURLS = {
  LOGIN: "/authenticate/login",
  VERIFY_TOKEN: "/verify-token",
  GRAPH: "https://graph.microsoft.com/v1.0/me",
  ROLES_LIST: "plan/getRoles",
  PLANNING_ELEMENTS: "plan/getPlan",
  FILTER_OPTIONS: "plan/getHierarchy",
  PRODUCT_HIERARCHY: "/api/getProductsHierarchy",
  PRODUCT_REFERENCEDATA: "/sellin/getCCMProductsReferenceData",
  PRODUCT_SELLINDATA: '/sellin/getCCMFillInData',
  PRODUCT_SALESOPSDATA: '/sellin/getSalesOpsRefernceAndSellIn',
  PRODUCT_SALESOPSDATA_NEW: '/sellin/getSalesOpsRefernceAndSellInNew',
  UPDATE_SELLINDATA: '/sellin/updateRsfPlan',
  PRODUCT_GROWTHDATA: '/sellin/getCCMGrowthPercentages',
  TERRITORY_PRODUCTDATA: '/sellin/territoryProduct',
  PRODUCT_REFERENCE_SELLOUTDATA: '/sellOut/getCCMSellOutRefernce',
  PRODUCT_FELLIN_SELLOUTDATA: '/sellOut/getCCMSellOutFillIn',
  PRODUCT_GROWTH_SELLOUTDATA: '/sellOut/getCCMSellOutGrowthPercentages',
  PRODUCT_UPDATE_SELLOUTDATA: '/sellOut/updateSelloutVolume',
  SELLOUT_SALESOPSDATA: 'sellOut/getSalesOpsSellout',
  SELLOUT_SELLOUTTOTALS: 'sellOut/getSelloutTotals',
  EMAIL_ONSUBMIT: 'api/emailOnSubmit',
  SELLOUT_TERRITORY_PRODUCTDATA: 'sellout/territoryProductSellout',
  FINANCE_REFERENCE_CCM_DATA: '/finance/getFinanceCCMRefernce',
  FINANCE_SALESOPSDATA: 'finance/getFinanceSalesops',
  FINANCE_CATEGORY_PRODUCT: 'finance/categoryTerritoryFinance',
  FINANCE_PLAN_CCM_DATA: '/finance/getFinanaceFillInAPI',
  FINANCE_UPDATE_CCM_DATA: '/finance/updateCCMFinance',
  FINANCE_FINANCEKPIS: 'finance/getFinanceKpis',
  OPEX_CCMC_DATA: '/opex/getOpexSquad',
  OPEX_UPDATE_DATA: '/opex/updateOpexSquad',
  OPEX_UL_DATA: '/opex/getOpexUnit',
  OPEX_SUMMARY_DATA: '/opex/getOpexSummary',
  OPEX_COUNTRY_DATA: '/opex/getOpexCountry',
  LAST_UPDATE_TIMESTAMP: 'api/getLastUpdatedTimestamps',
  ADD_PLAN_LOG: 'api/addPlanningLog',
  GET_PROGRESS_BAR_REPORT: 'api/getProgressBarReport',
  GET_TEST_URL:'authenticate/token'
};
